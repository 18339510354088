const updates = [
  {
    version: "1.6.1",
    description: "Adicionada funcionalidade de resetar a senha por e-mail.",
  },
  {
    version: "1.5.1",
    description: "Cria na tela de Home para usuários psicólogos, um dashboard.",
  },
  { version: "1.5.0", description: "Adicionada funcionalidade de agenda." },
  {
    version: "1.4.1",
    description: "Corrige problema no cadastro de pacientes.",
  },
  {
    version: "1.4.0",
    description: "Cria possibilidade de exportação para xlsx de algumas listas",
  },
  {
    version: "1.3.0",
    description: "Criação de filtros na página 'Meus pacientes'",
  },
  {
    version: "1.2.0",
    description: "Criação de filtros na página 'Meus atendimentos'",
  },
  {
    version: "1.1.0",
    description: "Criação de filtros na página 'Atendimentos por paciente'",
  },
  { version: "1.0.0", description: "Lançamento da versão" },
];

export default updates;
